import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { Box, CircularProgress, TextField } from '@mui/material';

import { Link } from 'react-router-dom';

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  useEffect(() => {
    // Fetch clients and counts from the API
    const fetchClients = async () => {
      try {
        const clientsResponse = await axios.get('/api/clients');
        const countsResponse = await axios.get('/api/clients/counts');
        
        const clientsData = clientsResponse.data;
        const countsData = countsResponse.data;

        // Merge counts into client data
        const clientsWithCounts = clientsData.map((client) => {
          const counts = countsData[client.id] || { enquiriesCount: 0, jobsCount: 0 };
          return { ...client, ...counts };
        });

        setClients(clientsWithCounts);
      } catch (error) {
        console.error('Error fetching clients or counts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

   // Filter clients based on search query
   const filteredClients = clients.filter((client) =>
    `${client.first_name} ${client.last_name}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (client.email && client.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (client.phone && client.phone.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const columns = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <Link
          to={`/clients/${params.row.id}`}
          className="text-indigo-600 hover:text-indigo-900 mr-4"
        >
           Details
        </Link>
      ),
    },
    { field: 'first_name', headerName: 'First Name', width: 200 },
    { field: 'last_name', headerName: 'Last Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'phone', headerName: 'Phone', width: 200 },
    { field: 'client_manager', headerName: 'Client Manager', width: 200 },
    { field: 'sales_manager', headerName: 'Sales Manager', width: 200 },
    {
      field: 'enquiriesCount',
      headerName: 'Enquiries Count',
      width: 150,
      renderCell: (params) => <span>{params.row.enquiriesCount || 0}</span>,
    },
    {
      field: 'jobsCount',
      headerName: 'Jobs Count',
      width: 150,
      renderCell: (params) => <span>{params.row.jobsCount || 0}</span>,
    },
    {
      field: 'tutorcruncher_id',
      headerName: 'TutorCruncher ID',
      width: 200,
      renderCell: (params) => {
        const tutorcruncherId = params.row?.tutorcruncher_id;
        const isOnTutorCruncher = Boolean(tutorcruncherId);
        return (
          <span
            className={`inline-flex items-center px-2 py-1 rounded-md text-xs font-medium ${
              isOnTutorCruncher ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
            }`}
          >
            {isOnTutorCruncher ? tutorcruncherId : 'Not on TutorCruncher'}
          </span>
        );
      },
    },
    
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box m={2} style={{ height: 700, width: '100%' }}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900 mb-4">
          Clients
        </h1>
      </div>
      <TextField
          label="Search Clients"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: '20px' }}
        />
      <DataGridPro
        rows={filteredClients}
        columns={columns}
        pageSizeOptions={[5, 10, 25, 50]}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
      />
    </Box>
  );
};

export default ClientList;
