import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the token from localStorage
    localStorage.removeItem('token');

    // Update the authentication state to false
    setIsAuthenticated(false);

    // Redirect to the login page
    navigate('/login');
  }, [navigate, setIsAuthenticated]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8">
      <h2 className="text-2xl font-bold">You have been logged out!</h2>
      <p className="text-gray-600 mt-4">Redirecting to the login page...</p>
    </div>
  );
};

export default Logout;
