import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import axios from "axios";


const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan",
  "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
  "Cambodia", "Cameroon", "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic",
  "Denmark", "Djibouti", "Dominica", "Dominican Republic",
  "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia",
  "Fiji", "Finland", "France",
  "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
  "Haiti", "Honduras", "Hong Kong", "Hungary",
  "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
  "Jamaica", "Japan", "Jordan",
  "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan",
  "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
  "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar",
  "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway",
  "Oman",
  "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
  "Qatar",
  "Romania", "Russia", "Rwanda",
  "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
  "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
  "Uganda", "Ukraine", "United Kingdom", "United States", "United Arab Emirates", "Uruguay", "Uzbekistan",
  "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
  "Yemen",
  "Zambia", "Zimbabwe"
];


const ClientDialog = ({ isOpen, closeModal, client, saveClient }) => {
  const [formData, setFormData] = useState({
    lead_status: 'New Client',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    country: '',
    customer_type: '',
    form_host: '',
    gclid: '',
    lead_source: '',
    how_did_they_hear_about_us: '',
    budget: '',
    approx_hours: '',
    school_year: '',
    create_enquiry: false, // Add field to track the checkbox
  });

  const [createEnquiry, setCreateEnquiry] = useState(false); // State for the checkbox
  const [error, setError] = useState('');
  
  

  useEffect(() => {
    if (client) {
      const mappedClient = {
        lead_status: client.lead_status || '',
        first_name: client.first_name || '',
        last_name: client.last_name || '',
        email: client.email || '',
        phone: client.phone || '',
        country: client.country || '',
        customer_type: client.customer_type || '',
        form_host: client.form_host || '',
        gclid: client.gclid || '',
        lead_source: client.lead_source || '',
        how_did_they_hear_about_us: client.how_did_they_hear_about_us || '',
        budget: client.budget || '',
        approx_hours: client.approx_hours || '',
        school_year: client.school_year || '',
      };
      setFormData(mappedClient);
    }
  }, [client]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const clientData = {
      ...formData,
      create_enquiry: createEnquiry, // Checkbox value
      enquiry_details: createEnquiry ? {
        job_title: "Placeholder",
        job_description: "Placeholder Description",
        charge_rate: 0,
        tutor_rate: 0,
        student_first_name: formData.first_name,
        student_last_name: formData.last_name,
        predicted_lessons: 0,
        salesbook_status: " ",
      } : null, // Only pass details if create_enquiry is true
    };
  
    try {
      // Log to see what data is being sent
      console.log("Submitting client data:", clientData);
  
      const savedClient = await saveClient(clientData);
  
      closeModal();  // Close the modal
    } catch (error) {
      console.error("Error creating client or enquiry:", error);
      setError("Failed to save client and/or create an enquiry");
    }
  };
  
  
  
  


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {client ? 'Edit Client' : 'Add New Client'}
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="mt-2 space-y-4">
                {error && <div className="text-red-500 text-sm">{error}</div>}

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="lead_status" className="block text-sm font-medium text-gray-700">
                       Status
                      </label>
                      <select
                        id="lead_status"
                        name="lead_status"
                        value={formData.lead_status}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      >
                        <option value="Select">Select</option>
                        <option value="New Lead">New Lead</option>
                        <option value="Existing Contact">Existing Contact</option>
                        
                      </select>
                    </div>
                    <div>
                      <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <input
                        id="first_name"
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        value={formData.last_name}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                        Phone
                      </label>
                      <input
                        id="phone"
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    
                    <div>
                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">
    Country
  </label>
                      
  <select
    id="country"
    name="country"
    value={formData.country}
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
    required
  >
   <option value="">Please Select</option>
    {countries.map((country) => (
      <option key={country} value={country}>
        {country}
      </option>
    ))}
  </select>

                    </div>
                    
                  </div>
                  
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="customer_type" className="block text-sm font-medium text-gray-700">
                        Customer Type
                      </label>
                      <select
                        id="customer_type"
                        name="customer_type"
                        value={formData.customer_type}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      ><option value="Please Select">Please Select</option>
                        <option value="Student">Student</option>
                        <option value="Parent">Parent</option>
                      </select>
                    </div>
                    {/* <div>
                      <label htmlFor="form_host" className="block text-sm font-medium text-gray-700">
                        Form Host
                      </label>
                      <input
                        id="form_host"
                        type="text"
                        name="form_host"
                        placeholder="Form Host"
                        value={formData.form_host}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div> */}
                    {/* <div>
                      <label htmlFor="gclid" className="block text-sm font-medium text-gray-700">
                        GCLID
                      </label>
                      <input
                        id="gclid"
                        type="text"
                        name="gclid"
                        placeholder="GCLID"
                        value={formData.gclid}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div> */}
                    <div>
                      <label htmlFor="lead_source" className="block text-sm font-medium text-gray-700">
                        Source
                      </label>
                      <select
                        id="lead_source"
                        name="lead_source"
                        value={formData.lead_source}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      >
                        <option value="Please Select">Please Select</option>
                        <option value="Contact Form">Contact Form</option>
                        <option value="Phone">Phone</option>
                        <option value="Email">Email</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Existing">Existing</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-4">
                    <label htmlFor="create-new-enquiry" className="text-sm font-medium text-gray-700">
                      Create New Enquiry
                    </label>
                    <input
  type="checkbox"
  id="create-new-enquiry"
  checked={createEnquiry}  // This should work fine
  onChange={() => setCreateEnquiry(!createEnquiry)}  // Toggling state
  className="ml-2"
/>

                  </div>

                  
                  <div className="grid grid-cols-1 gap-4">
                  {/* <div>
                      <label htmlFor="how_did_they_hear_about_us" className="block text-sm font-medium text-gray-700">
                        How Did They Hear About Us?
                      </label>
                      <select
                        id="how_did_they_hear_about_us"
                        name="how_did_they_hear_about_us"
                        value={formData.how_did_they_hear_about_us}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      >
                        <option value="Not Specified">Not Specified</option>
                        <option value="Google Search">Google Search</option>
                        <option value="Advertisement">Advertisement</option>
                        <option value="Blog Article">Blog Article</option>
                        <option value="Referred By Friend">Referred By Friend</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Study Resources">Study Resources</option>
                      </select>
                    </div>
                  </div>
                  <div>
    <label htmlFor="budget" className="block text-sm font-medium text-gray-700">
        Budget
    </label>
    <select
        id="budget"
        name="budget"
        value={formData.budget}
        onChange={handleChange}
        className="mt-1 px-3 py-2 border rounded-md w-full"
    ><option value="Please Select">Please Select</option>
        <option value="< 30">{"< 30"}</option>
        <option value="30 - 39">30 - 39</option>
        <option value="40 - 49">40 - 49</option>
        <option value="50 - 59">50 - 59</option>
        <option value="60 - 69">60 - 69</option>
        <option value="70 - 79">70 - 79</option>
        <option value="80+">80+</option>
        <option value="Unrestricted Budget">Unrestricted Budget</option>
    </select>
</div>


                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="approx_hours" className="block text-sm font-medium text-gray-700">
                        Approx Hours
                      </label>
                      <input
                        id="approx_hours"
                        type="number"
                        name="approx_hours"
                        placeholder="Approx Hours"
                        value={formData.approx_hours}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label htmlFor="school_year" className="block text-sm font-medium text-gray-700">
                        Year
                      </label>
                      <select
                        id="school_year"
                        name="school_year"
                        value={formData.school_year}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      ><option value="Please Select">Please Select</option>
                        <option value="13-14">13-14 (Year 9)</option>
                        <option value="14-15">14-15 (Year 10)</option>
                        <option value="15-16">15-16 (Year 11)</option>
                        <option value="16-17">16-17 (Year 12)</option>
                        <option value="17-18">17-18 (Year 13)</option>
                        <option value="University Student">University Student</option>
                      </select>
                    </div> */}
                 
                  </div>
                  <div className="mt-4 flex justify-end gap-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ClientDialog;
