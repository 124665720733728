import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   try {
  //     const response = await axios.post('https://portal.tutorchase.com/api/login', { email, password });
  //     console.log('Server Response:', response.data);
      
  //     if (response.data.token) {
  //       localStorage.setItem('token', response.data.token);
  //       setIsAuthenticated(true);
  //       navigate('/'); // Redirect to the default protected route
  //     } else {
  //       setError('Unexpected response format'); 
  //     }
  //   } catch (err) {
  //     console.error('Login error:', err.response || err); 
  //     setError('Invalid email or password'); 
  //   }
  //   setLoading(false);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);


    //${apiUrl}

    // Hardcoded Heroku URL for production
    const apiUrl = 'https://portal.tutorchase.com'; 

    try {
        const response = await axios.post(`/api/login`, { email, password });
        console.log('Server Response:', response.data);
        
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            setIsAuthenticated(true);
            navigate('/'); // Redirect to the default protected route
        } else {
            setError('Unexpected response format'); 
        }
    } catch (err) {
        console.error('Login error:', err.response || err); 
        setError('Invalid email or password or account deacitvated.'); 
    }
    
    setLoading(false);
};

  
  

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="TutorChase"
          src="logo192.png"
          className="mx-auto h-20 w-auto"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                required
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={loading}
            >
              {loading ? 'Signing in...' : 'Sign in'}
            </button>
          </div>
          {error && <p className="mt-2 text-center text-sm text-red-600">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
