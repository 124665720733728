import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';

const EditPackageModal = ({ isOpen, onClose, onSave, package: selectedPackage, enquiries = [] }) => {
  const [totalLessons, setTotalLessons] = useState('');
  const [lessonAmount, setLessonAmount] = useState('');
  const [totalCost, setTotalCost] = useState(0);
  const [selectedJob, setSelectedJob] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedPackage) {
      console.log('Selected package:', selectedPackage); // Debugging line

      setTotalLessons(selectedPackage.total_lessons || ''); // Use correct property name
      setLessonAmount(selectedPackage.lesson_amount || ''); // Use correct property name
      setTotalCost(selectedPackage.total_cost || 0); // Use correct property name
      setSelectedJob(selectedPackage.job_id || ''); // Use correct property name
    }
  }, [selectedPackage]);

  useEffect(() => {
    if (lessonAmount && totalLessons) {
      const total = lessonAmount * totalLessons;
      setTotalCost(total);
    } else {
      setTotalCost(0);
    }
  }, [lessonAmount, totalLessons]);

  const handleSave = () => {
    if (!totalLessons || !lessonAmount || !selectedJob) {
      setError('All fields are required');
      return;
    }

    const packageData = {
      totalLessons,
      lessonAmount,
      total: totalCost,
      job_id: selectedJob  // Include job_id here
    };

    onSave(packageData);
    onClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Edit Package
                </Dialog.Title>
                <form className="mt-2 space-y-4">
                  {error && <div className="text-red-500 text-sm">{error}</div>}

                  <div>
                    <label htmlFor="totalLessons" className="block text-sm font-medium text-gray-700">
                      Total Number of Lessons
                    </label>
                    <input
                      id="totalLessons"
                      type="number"
                      value={totalLessons}
                      onChange={(e) => setTotalLessons(e.target.value)}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="lessonAmount" className="block text-sm font-medium text-gray-700">
                      Lesson Amount (£)
                    </label>
                    <input
                      id="lessonAmount"
                      type="number"
                      value={lessonAmount}
                      onChange={(e) => setLessonAmount(e.target.value)}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="jobSelector" className="block text-sm font-medium text-gray-700">
                      Assign Package to Job
                    </label>
                    <select
                      id="jobSelector"
                      value={selectedJob}
                      onChange={(e) => setSelectedJob(e.target.value)}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                    >
                      <option value="">Select a Job</option>
                      {enquiries.map((enquiry) => (
                        <option key={enquiry.id} value={enquiry.id}>
                          {enquiry.job_title}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Total Cost: £{totalCost.toFixed(2)}
                    </label>
                  </div>

                  <div className="mt-4 flex justify-end gap-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditPackageModal;
