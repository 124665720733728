import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import NoteForm from './NoteForm';

const labels = [
  { name: 'Unlabelled', value: null },
  { name: 'Urgent', value: 'urgent' },
  { name: 'High Priority', value: 'high_priority' },
  { name: 'No Response', value: 'no_response' },
];

const dueDates = [
  { name: 'No due date', value: null },
  { name: 'Today', value: new Date().toISOString() },
  { name: 'Tomorrow', value: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString() },
];

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    height: 'auto',
    padding: '20px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const NoteEditModal = ({ isOpen, onClose, onSave, note, assignees }) => {
  const [noteData, setNoteData] = useState(null); // Initially null

  useEffect(() => {
    if (note) {
      console.log("Editing note object:", note); // Log the full note object for troubleshooting
      setNoteData({
        note_text: note.note_text || '',
        assigned: note.assigned || assignees[0],
        labelled: note.labelled || labels[0],
        dated: note.dated || dueDates[0],
        noteType: note.noteType || 'Note', // Set noteType dynamically from note
      });
    }
  }, [note, assignees]);
  
  

  const handleChange = (name, value) => {
    setNoteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (noteData) {
      onSave(noteData);
    }
  };

  // Wait for noteData to be initialized before rendering the form
  if (!noteData) {
    return null; // or a loading indicator if necessary
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Edit Note" style={customStyles}>
      <h2 className="text-lg font-semibold">Edit {noteData.noteType === 'Task' ? 'Task' : 'Note'}</h2>
      <NoteForm
        note_text={noteData.note_text}
        setNoteText={(text) => handleChange('note_text', text)}
        assigned={noteData.assigned}
        setAssigned={(assigned) => handleChange('assigned', assigned)}
        labelled={noteData.labelled}
        setLabelled={(labelled) => handleChange('labelled', labelled)}
        dated={noteData.dated}
        setDated={(dated) => handleChange('dated', dated)}
        assignees={assignees}
        onSave={handleSave}
        noteType={noteData.noteType} // Pass dynamically set noteType to NoteForm
      />
      <button onClick={onClose} className="mt-4 px-4 py-2 text-sm font-semibold text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300">
        Cancel
      </button>
    </Modal>
  );
};

export default NoteEditModal;
