import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';


const EnquiryEditModal = ({ 
  isOpen, 
  onClose, 
  onSave, 
  enquiry, 
  setEnquiryData, 
  clientFirstName, 
  clientLastName, 
  clientEmail 
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false); // Track loading state
  const [isStudentClient, setIsStudentClient] = useState(false); // Checkbox state


  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`${name} changed to: ${value}`);  // Log name and value to check the change
    setEnquiryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  

 

  // const handleCheckboxChange = () => {
  //   setIsStudentClient(!isStudentClient);
    
  //   if (!isStudentClient) {
  //     // Prefill student email with the client's email if "Student is the Client" is checked
  //     setEnquiryData((prevData) => ({
  //       ...prevData,
  //       student_first_name: clientFirstName,
  //       student_last_name: clientLastName,
  //       student_email: clientEmail, // Prefill email
  //     }));
  //   } else {
  //     // Clear the student fields if unchecked
  //     setEnquiryData((prevData) => ({
  //       ...prevData,
  //       student_first_name: '',
  //       student_last_name: '',
  //       student_email: '', // Clear email
  //     }));
  //   }
  // };

  
  const handleCheckboxChange = () => {
    setIsStudentClient((prev) => {
      const newState = !prev;
      
      // Update student data based on new state
      setEnquiryData((prevData) => ({
        ...prevData,
        student_first_name: newState ? clientFirstName : '', // Prefill first name if checked
        student_last_name: newState ? clientLastName : '',   // Prefill last name if checked
        // Set email only when needed
        student_email: newState ? clientEmail : prevData.student_email || '', // Avoid overwriting student email if already typed
      }));
  
      return newState;
    });
  };
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    console.log('Submitting enquiry:', enquiry); // Check the state of enquiry before submitting
  
    try {
      const studentData = {
        first_name: enquiry.student_first_name || clientFirstName,
        last_name: enquiry.student_last_name || clientLastName,
       // email: enquiry.student_email || clientEmail, 
      };
  
      console.log('Student Data before sending to TutorCruncher:', studentData);
  
      // Check if onSave is being triggered
      await onSave({ ...enquiry, student_email: studentData.email });  // Ensure email is correctly passed
  
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  
  
  

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Convert Enquiry to Job on TutorCruncher
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="mt-2 space-y-4">
                  <div>
                    <label htmlFor="job_title" className="block text-sm font-medium text-gray-700">
                      Job Title
                    </label>
                    <input
                      id="job_title"
                      type="text"
                      name="job_title"
                      value={enquiry.job_title}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  {/* <div>
                      <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                        Subject
                      </label>
                      <select
                        id="subject"
                        name="subject"
                        value={enquiry.subject}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      >
                        <option value="">Select Subject</option>
                        <option value="Maths">Maths</option>
                        <option value="Science">Science</option>
                        <option value="English">English</option>
                      </select>
                    </div> */}

                  <div>
                    <label htmlFor="job_description" className="block text-sm font-medium text-gray-700">
                      Job Description
                    </label>
                    <textarea
                      id="job_description"
                      name="job_description"
                      value={enquiry.job_description}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>
                  {/* Charge Rate and Tutor Rate side by side */}
  <div className="grid grid-cols-2 gap-4">
    <div>
      <label htmlFor="charge_rate" className="block text-sm font-medium text-gray-700">
        Charge Rate
      </label>
      <input
        id="charge_rate"
        type="number"
        name="charge_rate"
        value={enquiry.charge_rate}
        onChange={handleChange}
        className="mt-1 px-3 py-2 border rounded-md w-full"
        required
      />
    </div>
    <div>
      <label htmlFor="tutor_rate" className="block text-sm font-medium text-gray-700">
        Tutor Rate
      </label>
      <input
        id="tutor_rate"
        type="number"
        name="tutor_rate"
        value={enquiry.tutor_rate}
        onChange={handleChange}
        className="mt-1 px-3 py-2 border rounded-md w-full"
        required
      />
    </div>
  </div>

  <div className="flex items-center mt-4">
                    <input
                      id="isStudentClient"
                      type="checkbox"
                      checked={isStudentClient}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                    />
                    <label htmlFor="isStudentClient" className="text-sm font-medium text-gray-700">
                      Student is the Client
                    </label>
                  </div>

                  {/* Student First Name and Last Name side by side */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="student_first_name" className="block text-sm font-medium text-gray-700">
                        Student First Name
                      </label>
                      <input
                        id="student_first_name"
                        type="text"
                        name="student_first_name"
                        value={enquiry.student_first_name}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="student_last_name" className="block text-sm font-medium text-gray-700">
                        Student Last Name
                      </label>
                      <input
                        id="student_last_name"
                        type="text"
                        name="student_last_name"
                        value={enquiry.student_last_name}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                  </div>

                 {/* Student Email Field */}
{/* <div>
  <label htmlFor="student_email" className="block text-sm font-medium text-gray-700">
    Student Email
  </label>
  <input
    id="student_email"
    type="email"
    name="student_email"
    value={enquiry.student_email}
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
    required
    placeholder={isStudentClient ? clientEmail : 'Enter student email'}
  />
</div> */}


                 
              <div>
                      <label htmlFor="how_did_they_hear_about_us" className="block text-sm font-medium text-gray-700">
                        How Did They Hear About Us?
                      </label>
                      <select
                        id="how_did_they_hear_about_us"
                        name="how_did_they_hear_about_us"
                        value={enquiry.how_did_they_hear_about_us}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      >
                        <option value="Not Specified">Not Specified</option>
                        <option value="Google Search">Google Search</option>
                        <option value="Advertisement">Advertisement</option>
                        <option value="Blog Article">Blog Article</option>
                        <option value="Referred By Friend">Referred By Friend</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Study Resources">Study Resources</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Existing">Existing</option>
                      </select>
                    </div>
                 

                  <div>
    <label htmlFor="budget" className="block text-sm font-medium text-gray-700">
        Budget
    </label>
    <select
        id="budget"
        name="budget"
        value={enquiry.budget}
        onChange={handleChange}
        className="mt-1 px-3 py-2 border rounded-md w-full"
    ><option value="Please Select">Please Select</option>
        <option value="< 30">{"< 30"}</option>
        <option value="30 - 39">30 - 39</option>
        <option value="40 - 49">40 - 49</option>
        <option value="50 - 59">50 - 59</option>
        <option value="60 - 69">60 - 69</option>
        <option value="70 - 79">70 - 79</option>
        <option value="80+">80+</option>
        <option value="Unrestricted Budget">Unrestricted Budget</option>
    </select>
</div>


                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="approx_hours" className="block text-sm font-medium text-gray-700">
                        Approx Hours
                      </label>
                      <input
                        id="approx_hours"
                        type="number"
                        name="approx_hours"
                        placeholder="Approx Hours"
                        value={enquiry.approx_hours}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label htmlFor="school_year" className="block text-sm font-medium text-gray-700">
                        Year
                      </label>
                      <select
                        id="school_year"
                        name="school_year"
                        value={enquiry.school_year}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      ><option value="Please Select">Please Select</option>
                        <option value="13-14">13-14 (Year 9)</option>
                        <option value="14-15">14-15 (Year 10)</option>
                        <option value="15-16">15-16 (Year 11)</option>
                        <option value="16-17">16-17 (Year 12)</option>
                        <option value="17-18">17-18 (Year 13)</option>
                        <option value="University Student">University Student</option>
                      </select>
                    </div>
                    </div>
                  <div className="mt-4 flex justify-end gap-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    {isSubmitting ? (
  <div className="flex items-center justify-center">
    <span className="text-indigo-600 font-medium">Adding to TutorCruncher...</span>
    <svg
      className="animate-spin h-5 w-5 text-indigo-600 ml-2"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8v8H4z"
      ></path>
    </svg>
  </div>
) : (
  <button
    type="submit"
    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200"
  >
    Convert
  </button>
)}

                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EnquiryEditModal;

