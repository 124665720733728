import { useState, useEffect } from 'react';
import axios from 'axios';

const UserProfile = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null); // Preview of profile image
  const [isSaving, setIsSaving] = useState(false);

  // Fetch user profile data
  useEffect(() => {
    const fetchUserProfile = async () => {
        try {
          const token = localStorage.getItem('token'); // Assuming token is stored in local storage
          if (!token) {
            throw new Error('No token found');
          }
      
          const response = await axios.get('/api/current-user', {
            headers: { Authorization: `Bearer ${token}` },
          });
          
          const userData = response.data;
          setFirstName(userData.first_name);
          setLastName(userData.last_name);
          setEmail(userData.email);
          setProfileImagePreview(userData.profile_image);
          setLoading(false);
        } catch (error) {
          setError('Failed to fetch user profile');
          setLoading(false);
        }
      };
      
    
    fetchUserProfile();
  }, []);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      const updatedUserData = {
        first_name: firstName,
        last_name: lastName,
        email,
      };
      
      if (password) {
        updatedUserData.password = password;
      }

      await axios.put('/api/users/me', updatedUserData); // Adjust the endpoint as needed

      if (profileImage) {
        const formData = new FormData();
        formData.append('profileImage', profileImage);
        
        await axios.post('/api/users/me/upload', formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      

      setIsSaving(false);
    } catch (error) {
      setError('Failed to update profile');
      setIsSaving(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    
    // Show image preview
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-base font-semibold leading-6 text-gray-900">Edit Profile</h1>
      
      <form className="mt-4 space-y-4" onSubmit={handleProfileUpdate}>
        {/* First Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700">First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="mt-1 px-3 py-2 border rounded-md w-full"
          />
        </div>

        {/* Last Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            className="mt-1 px-3 py-2 border rounded-md w-full"
          />
        </div>

        {/* Email */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="mt-1 px-3 py-2 border rounded-md w-full"
          />
        </div>

        {/* Password */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Leave blank to keep current password"
            className="mt-1 px-3 py-2 border rounded-md w-full"
          />
        </div>

        {/* Profile Image */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Profile Image</label>
          {profileImagePreview && (
            <img src={profileImagePreview} alt="Profile" className="h-20 w-20 rounded-full mb-4" />
          )}
          <input
            type="file"
            onChange={handleImageChange}
            className="mt-1 px-3 py-2 border rounded-md w-full"
          />
        </div>

        {/* Save Button */}
        <div className="mt-4 flex justify-end">
          <button
            type="submit"
            disabled={isSaving}
            className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
