'use client'

import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import ClientDialog from './ClientDialog';
import { Link } from 'react-router-dom'; // Import if needed, otherwise remove

const ProposalBuilder = () => {
  const people = [
    {
      name: 'Jane Cooper',
      title: 'English Tutor',
      role: 'GCSE, A-Level, and University',
      email: 'janecooper@Tutors.com',
      telephone: '+1-202-555-0170',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
    // More people...
  ];

  return (
    <form>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Proposal Builder</h2>
          

         
        </div>

        <div className="border-b border-gray-900/10 pb-12">
         
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            
          <div className="sm:col-span-3">
              <label htmlFor="tutor" className="block text-sm font-medium leading-6 text-gray-900">
                Select Client
              </label>
              <div className="mt-2">
                <select
                  id="tutor"
                  name="tutor"
                  autoComplete="tutor-name"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option>#</option>
                
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="tutor" className="block text-sm font-medium leading-6 text-gray-900">
                Select Tutor
              </label>
              <div className="mt-2">
                <select
                  id="tutor"
                  name="tutor"
                  autoComplete="tutor-name"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option>#</option>
                 
                </select>
              </div>
            </div>

            

            

            
          </div>
        </div>

        
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
       
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Generate Proposal
        </button>
      </div>
    </form>
  )
}

export default ProposalBuilder;
