import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom'; // Import `useParams` correctly

import { Dialog, Transition } from '@headlessui/react';
import axios from "axios";

const SalesBookDialog = ({ isOpen, closeModal, client, saveClient, onSave }) => {
  const [formData, setFormData] = useState({
    lead_status: 'Existing Contact',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    country: '',
    customer_type: '',
    formHost: '',
    gclid: '',
    lead_source: '',
    how_did_they_hear_about_us: '',
    budget: '',
    approx_hours: '',
    school_year: '',
    subject: '',
    job_package: '',
    client_cost: '',
    comms: '',
    form_id: '',
    jobPackage: '',
    land_page: '',
    client_id: '', // Add client_id to the form data
    clientManager: '', // Add clientManager to the form data
  });

  const { clientId } = useParams();
  const [assignees, setAssignees] = useState([{ name: "Unassigned", value: null }]);
  const [clientManager, setClientManager] = useState(null);
  const [error, setError] = useState('');

  // Fetch client managers (assignees) from the database
  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const response = await axios.get('/api/users');
        const users = response.data.map((user) => ({
          name: user.email,
          value: user.email,
        }));
        setAssignees([{ name: "Unassigned", value: null }, ...users]);
      } catch (error) {
        console.error('Error fetching users:', error.message);
        setError('Failed to fetch client managers.');
      }
    };

    fetchAssignees();
  }, []);


  useEffect(() => {
    if (client) {
      console.log("Client data:", client);
      
      setFormData({
        client_id: client.client_id || clientId || '', // Ensure this field is correctly populated

        lead_status: client.lead_status || 'Existing Contact',
        first_name: client.first_name || '',
        last_name: client.last_name || '',
        email: client.email || '',
        phone: client.phone || '',
        country: client.country || '',
        customer_type: client.customer_type || '',
        formHost: client.formHost || '', // Ensure this is properly set
        gclid: client.gclid || '',
        lead_source: client.lead_source || '',
        how_did_they_hear_about_us: client.how_did_they_hear_about_us || '',
        budget: client.budget || '',
        approx_hours: client.approx_hours || '',
        school_year: client.school_year || '',
        subject: client.subject || '',
        job_package: client.package || '',
        client_cost: client.client_cost || '',
        comms: client.comms || '',
        form_id: client.form_id || '',
        land_page: client.land_page || '',
        clientManager: client.clientManager || '',
      });
    }
  }, [client, clientId]);
  

  
  


   // Handle form input change
   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = () => {
    const formData = {
        client_id: client.client_id,
        first_name: client.first_name,
        last_name: client.last_name,
        email: client.email,
        // Add other required fields here...
    };

    console.log("Form data:", formData); // Log the form data

    saveClient(formData); // Pass the form data to the save function
};

  
  
  



  


const handleManagerChange = async (e) => {
  const selectedManagerEmail = e.target.value;

  // Use formData.client_id or fallback to clientId from useParams
  const id = formData.client_id || clientId;

  if (!id) {
      setError('Client ID is missing. Cannot assign client manager.');
      return;
  }

  try {
      await axios.put(`/api/clients/${id}`, {
          clientManager: selectedManagerEmail,
          client_id: id  // Ensure client_id is passed as an integer, not undefined
      });
      setClientManager(selectedManagerEmail);
      setFormData((prevData) => ({
          ...prevData,
          clientManager: selectedManagerEmail,
      }));
  } catch (error) {
      console.error('Error assigning client manager:', error.message);
      setError('Failed to assign client manager.');
  }
};



  
  


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
  {client ? 'Edit SalesBook Entry' : 'Add New SalesBook Entry'}
</Dialog.Title>

                <form onSubmit={handleSubmit} className="mt-2 space-y-4">
                  {error && <div className="text-red-500 text-sm">{error}</div>}

                  <div className="grid grid-cols-2 gap-4">
                    {/* Add form fields similar to ClientDialog, but include all necessary SalesBook fields */}
                    <div>
  <label htmlFor="lead_status" className="block text-sm font-medium text-gray-700">
    Status
  </label>
  <select
    id="lead_status"
    name="lead_status"
    value={formData.lead_status}
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
    required
  >
    <option value="Please Select">Please Select</option>
    <option value="New Lead">New Lead</option>
    <option value="Existing Contact">Existing Contact</option>
  </select>
</div>
<div>
                      <label htmlFor="clientManager" className="block text-sm font-medium text-gray-700">
                        Client Manager
                      </label>
                      <select
  id="clientManager"
  name="clientManager"
  value={formData.clientManager}
  onChange={handleManagerChange}
  className="mt-1 px-3 py-2 border rounded-md w-full"
>
  <option value="">Assign Manager</option>
  {assignees.map((user) => (
    <option key={user.value} value={user.name}>
      {user.name} {/* Pass email as the value */}
    </option>
  ))}
</select>

                    </div>

                    <div>
                      <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <input
                        id="first_name"
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        value={formData.last_name}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                        Phone
                      </label>
                      <input
                        id="phone"
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <div>
  <label htmlFor="country" className="block text-sm font-medium text-gray-700">
    Country
  </label>
  <select
    id="country"
    name="country"
    value={formData.country}
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
    required
  >
    <option value="">Select</option>
    <option value="United States">United States</option>
    <option value="United Kingdom">United Kingdom</option>
    <option value="Canada">Canada</option>
    <option value="Australia">Australia</option>
    <option value="India">India</option>
    <option value="Germany">Germany</option>
    <option value="France">France</option>
    <option value="China">China</option>
    <option value="Japan">Japan</option>
    <option value="Brazil">Brazil</option>
  </select>
</div>

<div>
  <label htmlFor="customer_type" className="block text-sm font-medium text-gray-700">
    Customer Type
  </label>
  <select
  id="customer_type"
  name="customer_type"
  value={formData.customer_type} // Ensure this reflects the client data
  onChange={handleChange}
  className="mt-1 px-3 py-2 border rounded-md w-full"
  required
>

  <option value="student">Student</option>
  <option value="parent">Parent</option>
</select>

</div>
<div>
  <label htmlFor="formHost" className="block text-sm font-medium text-gray-700">
    Form Host
  </label>
  <input
    id="formHost"
    type="text"
    name="formHost"  // Make sure the name is "formHost"
    placeholder="Form Host"
    value={formData.formHost}  // Use formData.formHost
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
  />
</div>


                    <div>
                      <label htmlFor="gclid" className="block text-sm font-medium text-gray-700">
                        GCLID
                      </label>
                      <input
                        id="gclid"
                        type="text"
                        name="gclid"
                        placeholder="GCLID"
                        value={formData.gclid}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    <div>
  <label htmlFor="lead_source" className="block text-sm font-medium text-gray-700">
    Source
  </label>
  <select
    id="lead_source"
    name="lead_source"
    value={formData.lead_source}
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
  >
    <option value="">Select</option>
    <option value="Contact Form">Contact Form</option>
    <option value="Phone">Phone</option>
    <option value="Email">Email</option>
  </select>
</div>

<div>
  <label htmlFor="how_did_they_hear_about_us" className="block text-sm font-medium text-gray-700">
    How Did They Hear About Us?
  </label>
  <select
    id="how_did_they_hear_about_us"
    name="how_did_they_hear_about_us"
    value={formData.how_did_they_hear_about_us}
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
  >
    <option value="">Select</option>
    <option value="Not Specified">Not Specified</option>
    <option value="Google Search">Google Search</option>
    <option value="Advertisement">Advertisement</option>
    <option value="Blog Article">Blog Article</option>
    <option value="Referred By Friend">Referred By Friend</option>
    <option value="Social Media">Social Media</option>
    <option value="Study Resources">Study Resources</option>
  </select>
</div>

<div>
  <label htmlFor="budget" className="block text-sm font-medium text-gray-700">
    Budget
  </label>
  <select
    id="budget"
    name="budget"
    value={formData.budget}
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
  >
    <option value="">Select</option>
    <option value="< 30">{"< 30"}</option>
    <option value="30 - 39">30 - 39</option>
    <option value="40 - 49">40 - 49</option>
    <option value="50 - 59">50 - 59</option>
    <option value="60 - 69">60 - 69</option>
    <option value="70 - 79">70 - 79</option>
    <option value="80+">80+</option>
    <option value="Unrestricted Budget">Unrestricted Budget</option>
  </select>
</div>

                    <div>
                      <label htmlFor="approx_hours" className="block text-sm font-medium text-gray-700">
                        Approx Hours
                      </label>
                      <input
                        id="approx_hours"
                        type="number"
                        name="approx_hours"
                        placeholder="Approx Hours"
                        value={formData.approx_hours}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    <div>
  <label htmlFor="school_year" className="block text-sm font-medium text-gray-700">
    Year
  </label>
  <select
    id="school_year"
    name="school_year"
    value={formData.school_year}
    onChange={handleChange}
    className="mt-1 px-3 py-2 border rounded-md w-full"
  ><option value="">Select</option>
    <option value="13-14">13-14 (Year 9)</option>
    <option value="14-15">14-15 (Year 10)</option>
    <option value="15-16">15-16 (Year 11)</option>
    <option value="16-17">16-17 (Year 12)</option>
    <option value="17-18">17-18 (Year 13)</option>
    <option value="University Student">University Student</option>
  </select>
</div>

                    <div>
                      <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                        Subject
                      </label>
                      <select
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      >
                        <option value="">Select Subject</option>
                        <option value="Maths">Maths</option>
                        <option value="Science">Science</option>
                        <option value="English">English</option>
                      </select>
                    </div>
                  


                    <div>
                      <label htmlFor="client_cost" className="block text-sm font-medium text-gray-700">
                        Client Cost
                      </label>
                      <input
                        id="client_cost"
                        type="text"
                        name="client_cost"
                        placeholder="Client Cost"
                        value={formData.client_cost}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                   
                    <div>
                      <label htmlFor="comms" className="block text-sm font-medium text-gray-700">
                        Comms Cost
                      </label>
                      <input
                        id="comms"
                        type="text"
                        name="comms"
                        placeholder="Comms"
                        value={formData.comms}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="form_id" className="block text-sm font-medium text-gray-700">
                        Form ID
                      </label>
                      <input
                        id="form_id"
                        type="text"
                        name="form_id"
                        placeholder="Form ID"
                        value={formData.form_id}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    <div>
                      <label htmlFor="land_page" className="block text-sm font-medium text-gray-700">
                        Landing Page
                      </label>
                      <input
                        id="land_page"
                        type="text"
                        name="land_page"
                        placeholder="Land Page"
                        value={formData.land_page}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      />
                    </div>
                    
                  </div>

                  <div className="mt-4 flex justify-end gap-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SalesBookDialog;
