import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // to get clientId from the URL
import Select from 'react-select'; // Import react-select



const EnquiryDialog = ({ isOpen, closeModal, saveEnquiry, clients }) => {
  const { clientId } = useParams(); // Get clientId from the URL, if present
const [formData, setFormData] = useState({
  client_id: clientId || "", // Initialize to clientId if present
  job_title: '',
  status: 'New Enquiry',
});

  const [error, setError] = useState('');


  useEffect(() => {
    console.log("Client ID from URL:", clientId);
    if (clientId && clients.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        client_id: clientId,
      }));
    }
  }, [clientId, clients]);

  useEffect(() => {
    console.log("Clients List:", clients);
  }, [clients]);
  
  
  const formattedClientId = parseInt(clientId, 10); // or clientId.toString() if needed

  // Convert client data to react-select compatible options
const clientOptions = clients.map((client) => {
  const option = {
    value: client.id.toString(), // Convert value to string to match client_id type
    label: `${client.first_name} ${client.last_name}`,
  };
  //console.log("Client Option:", option);
  return option;
});

  
  console.log("Form Data:", formData);

  
      // Handle change for react-select
  const handleClientChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      client_id: selectedOption ? selectedOption.value : '',
    }));
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = ['client_id', 'job_title'];
  
    // Log the form data before submitting
    console.log("Submitting enquiry data:", formData);
  
    for (let field of requiredFields) {
      if (!formData[field]) {
        setError('All fields are required');
        return;
      }
    }
    
    saveEnquiry(formData);
    setError('');
    closeModal();
  };
  

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Add New Enquiry
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="mt-2 space-y-4">
                  {error && <div className="text-red-500 text-sm">{error}</div>}

                  <div>
                    <label htmlFor="client_id" className="block text-sm font-medium text-gray-700">
                      Select Client
                    </label>
                    <Select
  id="client_id"
  name="client_id"
  options={clientOptions}
  value={clientOptions.find((option) => option.value === formData.client_id) || null}
  onChange={handleClientChange}
  placeholder="Please Select or Search"
  className="mt-1 w-full"
  isClearable
/>


                  </div>

                  <div>
                    <label htmlFor="job_title" className="block text-sm font-medium text-gray-700">
                      Job Title
                    </label>
                    <input
                      id="job_title"
                      type="text"
                      name="job_title"
                      placeholder="Job Title"
                      value={formData.job_title}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  {/* <div>
                      <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                        Subject
                      </label>
                      <select
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="mt-1 px-3 py-2 border rounded-md w-full"
                      >
                        <option value="">Select Subject</option>
                        <option value="Maths">Maths</option>
                        <option value="Science">Science</option>
                        <option value="English">English</option>
                      </select>
                    </div>

                  

                  <div>
                    <label htmlFor="job_description" className="block text-sm font-medium text-gray-700">
                      Job Description
                    </label>
                    <textarea
                      id="job_description"
                      name="job_description"
                      placeholder="Job Description"
                      value={formData.job_description}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="charge_rate" className="block text-sm font-medium text-gray-700">
                      Charge Rate
                    </label>
                    <input
                      id="charge_rate"
                      type="number"
                      name="charge_rate"
                      placeholder="Charge Rate"
                      value={formData.charge_rate}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="tutor_rate" className="block text-sm font-medium text-gray-700">
                      Tutor Rate
                    </label>
                    <input
                      id="tutor_rate"
                      type="number"
                      name="tutor_rate"
                      placeholder="Tutor Rate"
                      value={formData.tutor_rate}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="student_first_name" className="block text-sm font-medium text-gray-700">
                      Student First Name
                    </label>
                    <input
                      id="student_first_name"
                      type="text"
                      name="student_first_name"
                      placeholder="Student First Name"
                      value={formData.student_first_name}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="student_last_name" className="block text-sm font-medium text-gray-700">
                      Student Last Name
                    </label>
                    <input
                      id="student_last_name"
                      type="text"
                      name="student_last_name"
                      placeholder="Student Last Name"
                      value={formData.student_last_name}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      required
                    />
                  </div> */}

                  <div className="mt-4 flex justify-end gap-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EnquiryDialog;
