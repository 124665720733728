import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  UserCircleIcon,
  TagIcon,
  CalendarIcon,
  PencilIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import NoteEditModal from "./NoteEditModal";
import axios from "axios";

const labelColors = {
  urgent: "bg-red-100 text-red-800",
  high_priority: "bg-yellow-100 text-yellow-800",
  no_response: "bg-gray-100 text-gray-800",
  unlabelled: "bg-gray-100 text-gray-800",
};

const labels = [
  { name: "Unlabelled", value: null },
  { name: "Urgent", value: "urgent" },
  { name: "High Priority", value: "high_priority" },
  { name: "No Response", value: "no_response" },
];

const NotesTab = () => {
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [assignedFilter, setAssignedFilter] = useState("");
  const [urgencyFilter, setUrgencyFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [dueFilter, setDueFilter] = useState("");
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [noteFormData, setNoteFormData] = useState({});
  const [clients, setClients] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(null); // Store the current logged-in user

  const location = useLocation();

  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, redirecting to login");
          return;
        }

        const response = await axios.get("/api/current-user", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoggedInUser(response.data);
        console.log("Logged in user:", response.data);
      } catch (error) {
        console.error("Error fetching logged-in user:", error);
      }
    };

    fetchLoggedInUser();
  }, []);

  useEffect(() => {
    if (!loggedInUser) {
      console.log("No logged-in user found yet.");
      return; // Prevent running fetchEnquiries() before loggedInUser is available
    }

    console.log("Logged in user is ready:", loggedInUser);
  }, [loggedInUser]);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const notesResponse = await axios.get("/api/notes");
        const notesData = notesResponse.data.map((note) => ({
          ...note,
          status: note.status || "Pending",
        }));
  
        // Initially, only show the logged-in user's tasks
        let filtered;
  
        // For Admins, initially show only their tasks
        if (loggedInUser?.role === "Admin") {
          filtered = notesData.filter(
            (note) => note.assigned_user === loggedInUser.email
          );
        } else {
          // Non-admins only see their own tasks
          filtered = notesData.filter(
            (note) => note.assigned_user === loggedInUser.email
          );
        }
  
        setNotes(notesData);
        setFilteredNotes(filtered); // Initially show only the logged-in user's tasks
      } catch (error) {
        console.error("Error fetching notes:", error);
        setNotes([]);
      }
    };
  
    if (loggedInUser) {
      fetchNotes();
    }
  }, [loggedInUser]);
  

  // Fetch assignees
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("/api/users");
        const users = response.data.map((user) => ({
          name: user.email,
          value: user.email,
        }));
        setAssignees([{ name: "Unassigned", value: null }, ...users]);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Fetch notes and apply initial noteId filter
  useEffect(() => {
    const fetchNotesWithClients = async () => {
      try {
        const notesResponse = await axios.get("/api/notes");
        const notesData = notesResponse.data.map((note) => ({
          ...note,
          status: note.status || "Pending",
        }));

        setNotes(notesData);

        // Get the query parameter value for noteId
        const queryParams = new URLSearchParams(location.search);
        const noteId = queryParams.get("noteId");

        // If noteId exists in the URL, set it as the search term and filter by it
        if (noteId) {
          setSearchTerm(noteId);
          const filtered = notesData.filter(
            (note) => note.id.toString() === noteId
          );
          setFilteredNotes(filtered);
        } else {
          setFilteredNotes(notesData);
        }
      } catch (error) {
        console.error("Error fetching notes:", error);
        setNotes([]);
      }
    };

    fetchNotesWithClients();
  }, [location.search]);

  // Apply filters when they change
useEffect(() => {
  const filterNotes = () => {
    let filtered = notes;

    // Filter out notes that are not of type "Task"
    filtered = filtered.filter((note) => note.type === "Task");

    if (searchTerm) {
      filtered = filtered.filter((note) =>
        note.id.toString().includes(searchTerm)
      );
    }

    // For Admins: filter by assigned user if `assignedFilter` is applied
    if (loggedInUser?.role === "Admin") {
      // If assignedFilter is not set, show only the Admin's tasks
      if (!assignedFilter) {
        filtered = filtered.filter(
          (note) => note.assigned_user === loggedInUser.email
        );
      } else {
        // If assignedFilter is set, show the tasks assigned to the selected user
        filtered = filtered.filter(
          (note) => note.assigned_user === assignedFilter
        );
      }
    }

    // Non-admin users should only see their own tasks
    if (loggedInUser && loggedInUser.role !== "Admin") {
      filtered = filtered.filter(
        (note) => note.assigned_user === loggedInUser.email
      );
    }

    if (urgencyFilter) {
      filtered = filtered.filter((note) => note.label === urgencyFilter);
    }

    if (dueFilter) {
      filtered = filtered.filter((note) => {
        const noteDueDate = new Date(note.due_date)
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("-"); // Convert to 'YYYY-MM-DD'
        return noteDueDate === dueFilter;
      });
    }

    if (statusFilter) {
      filtered = filtered.filter((note) => note.status === statusFilter);
    }

    setFilteredNotes(filtered);
  };

  filterNotes();
}, [
  searchTerm,
  assignedFilter,
  urgencyFilter,
  dueFilter,
  statusFilter,
  notes,
  loggedInUser,
]);

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Fetch clients
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsResponse = await axios.get("/api/clients");
        setClients(clientsResponse.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
        setClients([]);
      }
    };

    fetchClients();
  }, []);

  const handleMarkAsDone = async (noteId, clientId) => {
    try {
      await axios.put(`/api/clients/${clientId}/notes/${noteId}/status`, {
        status: "Done",
      });
      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === noteId ? { ...note, status: "Done" } : note
        )
      );
    } catch (error) {
      console.error("Error marking note as done:", error);
    }
  };

  const getClientName = (clientId) => {
    const client = clients.find((client) => client.id === clientId);
    return client
      ? `${client.first_name} ${client.last_name}`
      : "Unknown Client";
  };

  const handleEditNoteInternal = (note) => {
    setNoteFormData({
      client_id: note.client_id,
      note_text: note.note_text || "",
      assigned: note.assigned_user
        ? assignees.find((a) => a.value === note.assigned_user) || {
            name: "Unassigned",
            value: null,
          }
        : { name: "Unassigned", value: null },
      labelled: note.label
        ? labels.find((l) => l.value === note.label) || {
            name: "Unlabelled",
            value: null,
          }
        : { name: "Unlabelled", value: null },
      dated: note.due_date
        ? {
            name: new Date(note.due_date).toLocaleDateString(),
            value: new Date(note.due_date).toISOString(),
          }
        : { name: "No due date", value: null },
    });
    setEditingNoteId(note.id);
    setShowEditModal(true);
  };

  const handleDeleteNoteInternal = async (noteId, clientId) => {
    try {
      await axios.delete(`/api/clients/${clientId}/notes/${noteId}`);
      setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
      setFilteredNotes((prevFilteredNotes) =>
        prevFilteredNotes.filter((note) => note.id !== noteId)
      );
    } catch (error) {
      console.error("Error deleting note:", error.message);
    }
  };

  const handleSaveEditedNote = async (noteId, noteData) => {
    try {
      const dataToSend = {
        note_text: noteData.note_text || "",
        assigned_user: noteData.assigned?.value || null,
        label: noteData.labelled?.value || null,
        due_date: noteData.dated?.value
          ? new Date(noteData.dated.value).toISOString()
          : null,
      };

      await axios.put(
        `/api/clients/${noteData.client_id}/notes/${noteId}`,
        dataToSend
      );

      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === noteId ? { ...note, ...dataToSend } : note
        )
      );
      setShowEditModal(false);
    } catch (error) {
      console.error("Error saving edited note:", error.message);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <h1 className="mt-3 text-3xl font-bold tracking-tight text-slate-900 mb-4">
        Tasks
      </h1>

      {/* Filters */}
      <div className="flex space-x-4 mb-4">
        <input
          type="text"
          placeholder="Search by Note ID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />

        <input
          type="date"
          value={dueFilter}
          onChange={(e) => setDueFilter(e.target.value)}
          className="block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />

        {/* Conditionally render the "Assigned To" filter only for Admins */}
        {loggedInUser?.role === "Admin" && (
          <select
            value={assignedFilter}
            onChange={(e) => setAssignedFilter(e.target.value)}
            className="block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Assigned To</option>
            {assignees.map((assignee) => (
              <option key={assignee.value} value={assignee.value}>
                {assignee.name}
              </option>
            ))}
          </select>
        )}

        <select
          value={urgencyFilter}
          onChange={(e) => setUrgencyFilter(e.target.value)}
          className="block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Urgency</option>
          <option value="urgent">Urgent</option>
          <option value="high_priority">High Priority</option>
          <option value="no_response">No Response</option>
        </select>

        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="block w-1/4 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Status</option>
          <option value="Pending">Not Done</option>
          <option value="Done">Done</option>
        </select>

        {/* <select
          value={dueFilter}
          onChange={(e) => setDueFilter(e.target.value)}
          className="block w-1/3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Due</option>
        
        </select> */}
      </div>

      {/* <div className="flex mb-4">
        <button
          onClick={showAssignedToMe}
          className="mr-4 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Assigned To Me
        </button>
        <button
          onClick={showAllNotes}
          className="mr-4 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Show All
        </button>
      </div> */}

      {/* Notes List */}
      <div className="flow-root">
        {filteredNotes.length > 0 ? (
          <ul role="list" className="-mb-8">
            {filteredNotes.map((note, noteIdx) => (
              <li key={note.id}>
                <div className="relative pb-8">
                  {noteIdx !== filteredNotes.length - 1 ? (
                    <span
                      aria-hidden="true"
                      className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          labelColors[note.label] ||
                            "bg-gray-100 text-gray-800",
                          "flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white"
                        )}
                      >
                        <UserCircleIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-white"
                        />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-900 font-medium">
                          {note.note_text}
                        </p>

                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                          Created by: {note.created_user || "Unknown"}
                        </span>

                        <div className="flex items-center mt-1">
                          <UserCircleIcon className="h-5 w-5 text-gray-400 mr-2" />
                          <Link
                            to={`/clients/${note.client_id}`}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
                          >
                            Client: {getClientName(note.client_id)}
                          </Link>
                        </div>
                        {note.assigned_user && (
                          <div className="flex items-center mt-1">
                            <UserCircleIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                              Assigned To: {note.assigned_user}
                            </span>
                          </div>
                        )}
                        {note.label && (
                          <div className="flex items-center mt-1">
                            <TagIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <span
                              className={classNames(
                                labelColors[note.label] ||
                                  "bg-gray-100 text-gray-800",
                                "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium"
                              )}
                            >
                              Urgency: {note.label}
                            </span>
                          </div>
                        )}
                        {note.due_date ? (
                          <div className="flex items-center mt-1">
                            <CalendarIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                              Due:{" "}
                              {new Date(note.due_date).toLocaleDateString(
                                "en-GB"
                              )}{" "}
                              {/* dd/mm/yyyy format */}
                            </span>
                          </div>
                        ) : (
                          <div className="flex items-center mt-1">
                            <CalendarIcon className="h-5 w-5 text-gray-400 mr-2" />
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                              No due date
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <time dateTime={note.datetime}>
                        {new Date(note.created_at).toLocaleDateString()}
                      </time>

                      {/* Conditionally show the "Mark as Done" button only if the note type is "Task" */}
                      {note.type === "Task" &&
                        (note.status === "Done" ? (
                          <button
                            className="inline-flex items-center px-2 py-1 rounded-md bg-gray-200 text-gray-500 text-xs font-medium cursor-not-allowed"
                            disabled
                          >
                            <CheckCircleIcon className="h-4 w-4 mr-1" /> Done
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              handleMarkAsDone(note.id, note.client_id)
                            }
                            className="inline-flex items-center px-2 py-1 rounded-md bg-green-50 text-green-700 text-xs font-medium"
                          >
                            <CheckCircleIcon className="h-4 w-4 mr-1" /> Mark as
                            Done
                          </button>
                        ))}

                      {/* Edit and Delete buttons */}
                      <button
                        onClick={() => handleEditNoteInternal(note)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() =>
                          handleDeleteNoteInternal(note.id, note.client_id)
                        }
                        className="text-red-600 hover:text-red-900"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-gray-500">No notes available</div>
        )}
      </div>

      {/* Note Edit Modal */}
      {showEditModal && (
        <NoteEditModal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          onSave={(noteData) =>
            handleSaveEditedNote(editingNoteId, {
              ...noteData,
              client_id: noteFormData.client_id,
            })
          }
          note={noteFormData}
          setNoteText={(text) =>
            setNoteFormData((prev) => ({ ...prev, note_text: text }))
          }
          setAssigned={(assigned) =>
            setNoteFormData((prev) => ({ ...prev, assigned }))
          }
          setLabelled={(labelled) =>
            setNoteFormData((prev) => ({ ...prev, labelled }))
          }
          setDated={(dated) => setNoteFormData((prev) => ({ ...prev, dated }))}
          assignees={assignees}
        />
      )}
    </div>
  );
};

export default NotesTab;
