import React, { useState, useEffect } from 'react';

const Tutors = () => {
  const [tutors, setTutors] = useState([]);

  useEffect(() => {
    const fetchTutors = async () => {
      try {
        const response = await fetch('/api/tutors');
        const data = await response.json();
        setTutors(data);
      } catch (error) {
        console.error('Error fetching tutors:', error);
      }
    };

    fetchTutors();
  }, []);

  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {tutors.map((tutor) => (
        <li
          key={tutor.id}
          className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
        >
          <div className="flex flex-1 flex-col p-8">
            <h3 className="mt-6 text-sm font-medium text-gray-900">
              {tutor.first_name} {tutor.last_name}
            </h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Email</dt>
              <dd className="text-sm text-gray-500">{tutor.email || 'No email provided'}</dd>
            </dl>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Tutors;
